import './App.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import Chatroom from './Components/chatroom';
import SignIn from './Components/SignIn';

import { useAuthState } from 'react-firebase-hooks/auth';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

const auth = firebase.auth();

function App() {
  const [user] = useAuthState(auth);
  return (
    <div className='App'>
      <header></header>
      <section>
        {user ? <Chatroom /> : <SignIn />}
        {auth.currentUser ? (
          <button onClick={() => auth.signOut()}>Sign Out</button>
        ) : null}
      </section>
    </div>
  );
}

export default App;
